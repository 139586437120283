import axios from 'axios'

export const COLLECTION = 'MULTI-deca18';
export const CLASSIC_SC_ADDRESS = 'erd1qqqqqqqqqqqqpgqme3hpedgyty4sze4568y6jg75qmy5p7kvqvsauumd0';
export const PLAYERS_SC_ADDRESS = 'erd1qqqqqqqqqqqqqpgqgkhshja5ld5e52spqnk39f6ly5a8667tvqvsz3cjxr';
export const MULTIVERX_API = axios.create({
    baseURL: 'https://devnet-api.multiversx.com'
})
export const STAKING_API = axios.create({
    baseURL: 'https://devnet-staking.multi-fights.com'
})
